import React, { useEffect, useState } from 'react';
import { fetchJob } from '../../api/api';
import { Table, Tag, Modal, Pagination, Select } from 'antd';
import styles from './JobPage.module.css';
import { useToken } from '../../contexts/TokenContext';
import Header from '../../components/Header/Header';

function JobPage() {
  const [data, setData] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [selectedProfileId, setSelectedProfileId] = useState('');
  const [selectedFlowId, setSelectedFlowId] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [minRuntime, setMinRuntime] = useState('');
  const [error, setError] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(2); // State to manage page size
  const { token } = useToken();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!token) return;

      setLoading(true);
      try {
        const data = await fetchJob(token);
        setData(data);
        setError(null);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Unauthorized access. Please check your token.');
        } else if (error.response && error.response.status === 500) {
          setError('Server error. Please try again later.');
        } else {
          setError('Error fetching data. Please try again later.');
        }
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token]);

  useEffect(() => {
    // console.log(token);
  }, [token]);

  const handleUserChange = (event) => {
    setSelectedUserId(event.target.value);
    setCurrentPage(1);
  };

  const handleProfileChange = (event) => {
    setSelectedProfileId(event.target.value);
    setCurrentPage(1);
  };

  const handleFlowChange = (event) => {
    setSelectedFlowId(event.target.value);
    setCurrentPage(1);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
    setCurrentPage(1);
  };

  const handleMinRuntimeChange = (event) => {
    setMinRuntime(event.target.value);
    setCurrentPage(1);
  };

  const filterJobs = (user) => {
    return Object.entries(user.Profiles).reduce((acc, [profileId, profile]) => {
      if (selectedProfileId && profileId !== selectedProfileId) return acc;

      const filteredJobs = profile.Jobs.filter(job => {
        const meetsRuntimeCriteria = minRuntime ? job.TotalRunTime && job.TotalRunTime > minRuntime : true;
        const meetsStatusCriteria = selectedStatus ? job.Status === selectedStatus : true;
        const meetsFlowCriteria = selectedFlowId ? job.JobId === selectedFlowId : true;
        return meetsRuntimeCriteria && meetsStatusCriteria && meetsFlowCriteria;
      });

      if (filteredJobs.length > 0) {
        acc.push({ profileId, profileName: profile.ProfileName, jobs: filteredJobs });
      }
      return acc;
    }, []);
  };

  const filteredUsers = data ? Object.entries(data.users).reduce((acc, [userId, user]) => {
    if (selectedUserId && userId !== selectedUserId) return acc;

    const filteredProfiles = filterJobs(user);

    if (filteredProfiles.length > 0) {
      acc[userId] = { ...user, Profiles: filteredProfiles };
    }
    return acc;
  }, {}) : {};

  const allJobs = Object.entries(filteredUsers).flatMap(([userId, user]) =>
    user.Profiles.flatMap(profile =>
      profile.jobs.map(job => ({
        userName: user.UserName,
        profileName: profile.profileName,
        ...job
      }))
    )
  ).sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));  // Sorting by timestamp

  const paginatedJobs = allJobs.slice((currentPage - 1) * pageSize, currentPage * pageSize);
  // console.log(paginatedJobs)
  const columns = [
    {
      title: "Analysis Engine",
      dataIndex: "type",
      key: "type",
      width: 400,
    },
    {
      title: "Output Tags",
      dataIndex: "strengths_list",
      key: "strengths_list",
      render: (strengths_list) => (
        <div>
          {strengths_list.map((item, index) => (
            <Tag
              key={index}
              className={styles.antTag}
              onClick={() => showModal(item.strengths, item.strength_keyword)}
            >
              {item.strength_keyword}
            </Tag>
          ))}
        </div>
      ),
    }
  ];

  const TruncatedText = ({ text = '', maxWords }) => {
    const [isTruncated, setIsTruncated] = useState(true);
  
    const toggleTruncate = () => {
      setIsTruncated(!isTruncated);
    };
  
    const words = text.split(' ');
    const displayText = isTruncated ? words.slice(0, maxWords).join(' ') + (words.length > maxWords ? '...' : '') : text;
  
    return (
      <div style={{ position: 'relative', paddingBottom: words.length > maxWords ? '1.5em' : '0' }}>
        <p 
          dangerouslySetInnerHTML={{ __html: displayText }} style={{ margin: '0 0 1em 0' }} 
        />
        {words.length > maxWords && (
          <button
            onClick={toggleTruncate}
            style={{
              position: 'absolute',
              bottom: '1px',
              right: 0,
              background: 'none',
              border: 'none',
              color: 'green',
              cursor: 'pointer',
              fontSize: '12px',
              fontFamily: 'Inter, sans-serif',
              fontWeight: 600,
              lineHeight: '18px',
              letterSpacing: '0%',
              marginBottom: '12px'
            }}
          >
            {isTruncated ? 'Show more' : 'Show less'}
          </button>
        )}
      </div>
    );
  };

  const showModal = (strengths, strength_keyword) => {
    const groupEvidenceByLevel = (evidence) => {
      return Object.entries(evidence).reduce((acc, [key, value]) => {
        const levels = key.split('_').map(part => isNaN(Number(part)) ? null : Number(part));
        const primaryLevel = levels[1] !== null ? levels[1] : 'General'; 
        const title = typeof primaryLevel === 'number' ? `Level ${primaryLevel} Evidence` : `${primaryLevel} Evidence`;
        if (value === "" || (Array.isArray(value) && value.length === 0)) {
          return acc;
        }
        acc[title] = acc[title] || [];
        if (Array.isArray(value) && value.length > 0 && value[0].url && value[0].url_text) {
          acc[title].push(value);
        } 
        else if (typeof value === 'object' && value !== null && value.intro) {
          acc[title].push({ intro: value.intro, urls: value.urls });
        } 
        else {
          acc[title].push(value);
        }
  
        return acc;
      }, {});
    };
  
    const content = (
      <ol>
        {strengths.map((strength, index) => (
          <li key={index}>
            <p><strong>Statement:</strong></p>
            <TruncatedText text={strength.description} maxWords={100} />
            {!strength.evidence && strength.background && strength.background !== "Not Applicable." && (
              <>
                <p><strong>Supporting Information:</strong></p>
                <TruncatedText text={strength.background} maxWords={100} />
              </>
            )}
            {strength.evidence && (
              <>
                <p><strong>Evidence:</strong></p>
                {Object.entries(groupEvidenceByLevel(strength.evidence)).map(([title, evidenceList], evidenceIndex) => (
                  <div key={evidenceIndex}>
                    <p style={{ fontSize: '14px' }}>{title}:</p>
                    {evidenceList.map((value, index) => (
                      Array.isArray(value) ? (
                        value.map((item, itemIndex) => (
                          <p key={itemIndex}>
                            <a href={item.url} target="_blank" rel="noopener noreferrer">{item.url_text}</a>
                          </p>
                        ))
                      ) : typeof value === 'string' ? (
                        <TruncatedText key={index} text={value} maxWords={100} />
                      ) : (
                        value.intro ? (
                          <div key={index}>
                            <p>{value.intro}</p>
                            {value.urls && Object.entries(value.urls).map(([urlKey, urlValue], urlIndex) => (
                              <p key={urlIndex}>
                                <a href={urlValue.url} target="_blank" rel="noopener noreferrer">{urlValue.url_text}</a>
                              </p>
                            ))}
                          </div>
                        ) : (
                          value.url && value.url_text && (
                            <p key={index}>
                              <a href={value.url} target="_blank" rel="noopener noreferrer">{value.url_text}</a>
                            </p>
                          )
                        )
                      )
                    ))}
                  </div>
                ))}
              </>
            )}
          </li>
        ))}
      </ol>
    );
  
    setModalContent(content);
    setModalTitle(strength_keyword);
    setIsModalVisible(true);
  };
    
  const showIndRecommenderModal = (recommender) => {
    const content = (
      <div>
        {recommender.name && <p><strong>Name:</strong> {recommender.name}</p>}
        {recommender.field && <p><strong>Field:</strong> {recommender.field}</p>}
        {recommender.employer && <p><strong>Employer:</strong> {recommender.employer}</p>}
        {recommender.title && <p><strong>Title:</strong> {recommender.title}</p>}
        {recommender.candidate_paper_title && <p><strong>Paper Title:</strong> {recommender.candidate_paper_title}</p>}
        {recommender.candidate_paper_publisher && <p><strong>Paper Publisher:</strong> {recommender.candidate_paper_publisher}</p>}
        {recommender.candidate_paper_year && <p><strong>Year:</strong> {recommender.candidate_paper_year}</p>}
        {recommender.percentage && <p><strong>Percentage:</strong> {recommender.percentage}</p>}
        {recommender.t_scholar_id && <p><strong>Scholar ID:</strong> {recommender.t_scholar_id}</p>}
      </div>
    );

    setModalContent(content);
    setModalTitle(recommender.name);
    setIsModalVisible(true);
  };

  const showOtherRecommenderModal = (recommender) => {
    const content = (
      <div>
        {recommender.name && (<p><strong>Name:</strong> {recommender.scholarName}</p>)}
        {recommender.field && (<p><strong>Field:</strong> {recommender.field}</p>)}
        {recommender.employer && (<p><strong>Employer:</strong> {recommender.employer}</p>)}
        {recommender.citation && (<p><strong>Citations:</strong> {recommender.citation}</p>)}
        {recommender.publication && (<p><strong>Publications:</strong> {recommender.publication}</p>)}
        {recommender.worldRank && (<p><strong>World Rank:</strong> {recommender.worldRank}</p>)}
        {recommender.t_scholar_id && (<p><strong>Scholar ID:</strong> {recommender.t_scholar_id}</p>)}
        {recommender.link && (<p><strong>Link:</strong> <a href={recommender.link} target="_blank" rel="noopener noreferrer">Profile</a></p>)}
        
      </div>
    );
    setModalContent(content);
    setModalTitle(recommender.scholarName);
    setIsModalVisible(true);
  };

  const showDepenndentRecommenderModal = (recommender) => {
    const content = (
      <div>
        {recommender.name && (<p><strong>Name:</strong> {recommender.name}</p>)}
        {recommender.field && (<p><strong>Field:</strong> {recommender.field}</p>)}
        {recommender.employer && (<p><strong>Employer:</strong> {recommender.employer}</p>)}
        {recommender.citation && (<p><strong>Citations:</strong> {recommender.citation}</p>)}
        {recommender.publication && (<p><strong>Publications:</strong> {recommender.publication}</p>)}
        {recommender.worldRank && (<p><strong>World Rank:</strong> {recommender.worldRank}</p>)}
        {recommender.t_scholar_id && (<p><strong>Scholar ID:</strong> {recommender.t_scholar_id}</p>)}
        {recommender.link && (<p><strong>Link:</strong> <a href={recommender.link} target="_blank" rel="noopener noreferrer">Profile</a></p>)}
        
      </div>
    );
    setModalContent(content);
    setModalTitle(recommender.scholarName);
    setIsModalVisible(true);
  };

  const Engine = {
    edu: "Educational and Professional Background",
    award: "Award Analysis",
    publication: "Publication and Citation Analysis",
    member: "Membership Analysis",
    media: "Media Coverage Analysis",
    review: "Journal Review Analysis",
    patent: "Patent Analysis",
    art: "Art Exhibition Analysis",
    job_title: "Job Title Analysis",
  };

  const generateDataSource = (tags) => {
    return tags.reduce((acc, item) => {
      // Skip if tag_dict is not present or empty
      if (!item.tag_dict || item.tag_dict.length === 0) {
        return acc;
      }
      
      const tag = item.tag_dict[0];
      const index = acc.findIndex((entry) => entry.type === Engine[tag.type]);
      
      if (index > -1) {
        acc[index].strengths_list.push({
          strength_keyword: tag.strength_keyword,
          strengths: tag.strengths && tag.strengths.length > 0 ? tag.strengths : [{ description: tag.description, background: tag.background }],
        });
      } else {
        acc.push({
          key: Engine[tag.type],
          type: Engine[tag.type],
          strengths_list: [
            {
              strength_keyword: tag.strength_keyword,
              strengths: tag.strengths && tag.strengths.length > 0 ? tag.strengths : [{ description: tag.description, background: tag.background }],
            },
          ],
        });
      }
      return acc;
    }, []);
  };

  const renderRecommenders = (recommenders) => {
    if (!recommenders) return null;

    return (
      <div>
        {recommenders.map((recGroup, groupIndex) => (
          <div key={groupIndex}>
            {recGroup.independent_recommenders && recGroup.independent_recommenders.length > 0 && (
              <div>
                <h4>Independent Recommenders</h4>
                {recGroup.independent_recommenders.map((rec, index) => (
                  <Tag key={index} className={styles.antTag} onClick={() => showIndRecommenderModal(rec)}>
                    {rec.name || 'N/A'}
                  </Tag>
                ))}
              </div>
            )}
            {recGroup.other_recommenders && recGroup.other_recommenders.length > 0 && (
              <div>
                <h4>Other Recommenders</h4>
                {recGroup.other_recommenders.map((rec, index) => (
                  <Tag key={index} className={styles.antTag} onClick={() => showOtherRecommenderModal(rec)}>
                    {rec.scholarName || 'N/A'}
                  </Tag>
                ))}
              </div>
            )}
            {recGroup.dependent_recommenders && recGroup.dependent_recommenders.length > 0 && (
              <div>
                <h4>Dependent Recommenders</h4>
                {recGroup.dependent_recommenders.map((rec, index) => (
                  <Tag key={index} className={styles.antTag} onClick={() => showDepenndentRecommenderModal(rec)}>
                    {rec.name || 'N/A'}
                  </Tag>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <Header text="Job Status" loading={loading} />
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {!error && loading && <p>Loading...</p>}
      {!error && !loading && !data && <p>No data available.</p>}
      {!error && data && (
        <>
          <div className={styles.filterContainer}>
            <label>
              User:
              <select onChange={handleUserChange} value={selectedUserId}>
                <option value="">All Users</option>
                {Object.entries(data.users).map(([userId, user]) => (
                  <option key={userId} value={userId}>
                    {user.UserName}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Profile:
              <select onChange={handleProfileChange} value={selectedProfileId}>
                <option value="">All Profiles</option>
                {Object.entries(data.users).flatMap(([userId, user]) =>
                  Object.entries(user.Profiles).map(([profileId, profile]) => (
                    <option key={profileId} value={profileId}>
                      {profile.ProfileName}
                    </option>
                  ))
                )}
              </select>
            </label>
            <label>
              Job:
              <select onChange={handleFlowChange} value={selectedFlowId}>
                <option value="">All Jobs</option>
                {Object.entries(data.users).flatMap(([userId, user]) =>
                  Object.entries(user.Profiles).flatMap(([profileId, profile]) =>
                    profile.Jobs.map(job => (
                      <option key={job.JobId} value={job.JobId}>
                        {job.JobName}
                      </option>
                    ))
                  )
                )}
              </select>
            </label>
            <label>
              Status:
              <select onChange={handleStatusChange} value={selectedStatus}>
                <option value="">All Statuses</option>
                <option value="SUCCEEDED">SUCCEEDED</option>
                <option value="FAILED">FAILED</option>
              </select>
            </label>
            <label>
              Min Runtime:
              <input type="number" onChange={handleMinRuntimeChange} value={minRuntime} />
            </label>
          </div>
          {paginatedJobs.length > 0 ? (
            <>
              {paginatedJobs.map(job => (
                <React.Fragment key={job.JobId}>
                  <h2 className={styles.userSection}>{job.userName}</h2>
                  <h3 className={styles.profileSection}>{job.profileName}</h3>
                  <div className={styles.jobSection}>
                    <p>
                      <span style={{ fontWeight: 'bold' }}>Job Name:</span> {job.JobName} - <span style={{ fontWeight: 'bold' }}>Status:</span> {job.Status} - <span style={{ fontWeight: 'bold' }}>Total Run Time:</span> {job.TotalRunTime || 'N/A'}
                    </p>
                    <p>
                      <span style={{ fontWeight: 'bold' }}>Job Url:</span> <a href={job.job_url} target="_blank" rel="noopener noreferrer">Click Here to Jump</a>
                    </p>
                    {job.tags && job.tags.length > 0 && (
                      <Table
                        className={styles.antTable}
                        dataSource={generateDataSource(job.tags)}
                        columns={columns}
                        pagination={false}
                        size="small"
                      />
                    )}
                    {renderRecommenders(job.recommenders)}
                  </div>
                </React.Fragment>
              ))}
              <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={allJobs.length}
              onChange={(page) => setCurrentPage(page)}
              showSizeChanger
              onShowSizeChange={(current, size) => setPageSize(size)}
              pageSizeOptions={['2', '5', '10']}
            />
            </>
          ) : (
            <p>No users match.</p>
          )}
        </>
      )}
      <Modal
        title={modalTitle}
        open={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        className={styles.antModalContent}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

export default JobPage;
