import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { signOut } from 'aws-amplify/auth';
import styles from './Sidebar.module.css';

function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const handleNavigation = async (path) => {
    if (path === '/logout') {
      try {
        await signOut();
        console.log('Signed out successfully');
        navigate('/');
      } catch (error) {
        console.error('Error signing out: ', error);
      }
    } else {
      navigate(`${path}?${queryParams.toString()}`);
    }
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <div className={styles.sidebar}>
      <div className={styles.navigationButtons}>
        <button
          className={`${styles.sidebarButton} ${isActive('/user') ? styles.sidebarButtonActive : ''}`}
          onClick={() => handleNavigation('/user')}
        >
          User Management
        </button>
        <button
          className={`${styles.sidebarButton} ${isActive('/job') ? styles.sidebarButtonActive : ''}`}
          onClick={() => handleNavigation('/job')}
        >
          Job Status
        </button>
        <button
          className={`${styles.sidebarButton} ${isActive('/resume') ? styles.sidebarButtonActive : ''}`}
          onClick={() => handleNavigation('/resume')}
        >
          Resume Extraction
        </button>
        <button
          className={`${styles.sidebarButton} ${isActive('/questions') ? styles.sidebarButtonActive : ''}`}
          onClick={() => handleNavigation('/questions')}
        >
          User Questions
        </button>
      </div>
      <div className={styles.logoutButton}>
        <button
          className={`${styles.sidebarButton} ${isActive('/logout') ? styles.sidebarButtonActive : ''}`}
          onClick={() => handleNavigation('/logout')}
        >
          Logout
        </button></div>
    </div>
  );
}

export default Sidebar;
