import { useState, useEffect } from 'react';
import { fetchUsers } from '../api/api';

export function useUsers(token) {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false); // Optional: To handle loading state
    const [error, setError] = useState(null); // Optional: To handle error state

    useEffect(() => {
        let isActive = true;
        const loadUsers = async () => {
            setLoading(true);
            setError(null);

            try {
                let allUsers = [];
                let hasMore = true;
                let page = 1;

                while (hasMore && isActive) {
                    const response = await fetchUsers(token, page);
                    if (response && Array.isArray(response)) {
                        allUsers = [...allUsers, ...response];
                        hasMore = response.length === 10000;
                        page++;
                    } else {
                        hasMore = false; // Stop if response is not as expected
                    }
                }

                if (isActive) setUsers(allUsers);
            } catch (err) {
                if (isActive) setError(err);
            } finally {
                if (isActive) setLoading(false);
            }
        };

        if (token && isActive) loadUsers();

        return () => {
            isActive = false; // Clean up function to prevent setting state on unmounted component
        };
    }, [token]);

    return { users, loading, error }; // Optional: Returning loading and error state
}
