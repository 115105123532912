import React from 'react';
import { Spin } from 'antd';
import styles from './Header.module.css';

const Header = ({ text, loading }) => (
    <div className={styles.header}>
        <h1>{text}</h1>
        {loading && <Spin size="large" className={styles.spinner} />}
    </div>
);

export default Header;
