import React from 'react';
import { Table, Input, Space, Button, Pagination } from 'antd';
import styles from './UserTable.module.css';

const UserTable = ({
    columns,
    tableData,
    expandedRowKeys,
    handleExpand,
    handleAccept,
    handleRejectClick,
    handleSaveChanges,
    handleTableCancel,
    currentPage,
    totalUsers,
    handlePageChange,
    loadingUserId,
    ACCEPTED,
    REJECTED,
    setTableData,
}) => (
    <>
        <Table
            bordered
            columns={columns}
            dataSource={tableData}
            rowKey={(row) => row.user_id}
            expandable={{
                expandedRowRender: (record) => (
                    <div className={styles.rowItem}>
                        <div className={styles.item}>
                            <div className={styles.title}>Business name</div>
                            <div className={styles.value}>{record.company}</div>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.title}>User Description</div>
                            <div className={styles.value}>
                                <Input
                                    value={record.annotation.description}
                                    onChange={(e) => {
                                        record.annotation.description = e.target.value;
                                        setTableData([...tableData]);
                                    }}
                                />
                            </div>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.title}>User ID</div>
                            <div className={styles.value}>{record.user_id}</div>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.title}>Account info</div>
                            <div className={styles.value}>
                                <Input
                                    value={record.annotation.account_info}
                                    onChange={(e) => {
                                        record.annotation.account_info = e.target.value;
                                        setTableData([...tableData]);
                                    }}
                                />
                            </div>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.title}>Action</div>
                            <div className={styles.value}>
                                <Space>
                                    <Button
                                        size="small"
                                        type="primary"
                                        disabled={record.user_status === ACCEPTED || loadingUserId === record.user_id}
                                        onClick={() => handleAccept(record)}
                                    >
                                        Approve
                                    </Button>
                                    <Button
                                        size="small"
                                        type="primary"
                                        disabled={record.user_status === REJECTED || loadingUserId === record.user_id}
                                        danger
                                        onClick={() => handleRejectClick(record)}
                                    >
                                        Reject
                                    </Button>
                                    <Button
                                        size="small"
                                        style={{ backgroundColor: '#72c040', color: '#fff' }}
                                        disabled={loadingUserId === record.user_id}
                                        onClick={() => handleSaveChanges(record)}
                                    >
                                        Save Changes
                                    </Button>
                                    <Button size="small" type="default" onClick={() => handleTableCancel(record.user_id)}>
                                        Cancel
                                    </Button>
                                </Space>
                            </div>
                        </div>
                    </div>
                ),
                rowExpandable: (record) => true,
                expandedRowKeys: expandedRowKeys,
                onExpand: (expanded, record) => handleExpand(record),
            }}
            onRow={(record) => ({
                onClick: () => handleExpand(record),
            })}
            pagination={false}
        />
        <Pagination
            current={currentPage}
            pageSize={10}
            total={totalUsers}
            onChange={handlePageChange}
            showSizeChanger={false}
            style={{ marginTop: '16px', textAlign: 'center' }}
        />
    </>
);

export default UserTable;
