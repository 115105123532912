import React, { useState, useEffect } from 'react';
import { updateUserDecision, recordUserNote, fetchUsers, fetchTotalUsers } from '../../api/api';
import { useUsers } from '../../hooks/useUsers';
import { message } from 'antd';
import Header from '../../components/Header/Header';
import UserTable from './UserTable/UserTable';
import RejectUserModal from '../../components/RejectUserModal/RejectUserModal';
import '@aws-amplify/ui-react/styles.css';
import { useToken } from '../../contexts/TokenContext';
import styles from './UserPage.module.css';

function UserPage() {
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loadingUserId, setLoadingUserId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const { token } = useToken();

  const ACCEPT = 'activate';
  const REJECT = 'reject';
  const ACCEPTED = 'ACTIVE';
  const REJECTED = 'REJECTED';

  const columns = [
    {
      title: 'Name',
      dataIndex: 'full_name',
      key: 'name',
      render: (text, record) => <a>{`${record.first_name} ${record.last_name}`}</a>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Status',
      dataIndex: 'user_status',
      key: 'status',
      render: (status, record) => {
        if (loadingUserId === record.user_id) {
          return 'Processing...';
        }
        return status;
      }
    }
  ];

  const { users, loading, error } = useUsers(token);

  useEffect(() => {
    setTableData(users);
  }, [users]);

  useEffect(() => {
    if (!token) return;

    fetchTotalUsers(token).then(total => setTotalUsers(total));
    handleQueryUsers(1);
  }, [token]);


  const handleQueryUsers = async (page = 1) => {
    if (token) {
      if (totalUsers === 0) {
        const total = await fetchTotalUsers(token); // Fetch total users if not already fetched
        setTotalUsers(total);
      }
      const users = await fetchUsers(token, page);
      setTableData(users);
      setCurrentPage(page); // Update the current page
    }
  };

  // call backend api to accept
  const handleAccept = async (record) => {
    setLoadingUserId(record.user_id);
    updateTableDataStatus(record.user_id, 'Processing...');
    try {
      await updateUserDecision(token, record.user_id, { action: ACCEPT });
      message.success('User approved successfully!');
      updateTableDataStatus(record.user_id, ACCEPTED);
    } catch (error) {
      message.error('Failed to approve user.', error);
      updateTableDataStatus(record.user_id, record.user_status);
    } finally {
      setLoadingUserId(null);
    }
  };

  // call backend api to reject
  const handleReject = async (record) => {
    setLoadingUserId(record.user_id);
    setIsRejectModalVisible(false);
    updateTableDataStatus(record.user_id, 'Processing...');
    try {
      await updateUserDecision(token, record.user_id, { action: REJECT });
      message.success('User rejected successfully!');
      updateTableDataStatus(record.user_id, REJECTED);
    } catch (error) {
      message.error('Failed to reject user.', error);
      updateTableDataStatus(record.user_id, record.user_status);
    } finally {
      setLoadingUserId(null);
    }
  };

  // save changes in nodes
  const handleSaveChanges = async (record) => {
    const noteData = {};

    if (record.annotation?.description) {
      noteData.description = record.annotation.description;
    }

    if (record.annotation?.account_info) {
      noteData.account_info = record.annotation.account_info;
    }

    if (Object.keys(noteData).length === 0) {
      message.error('Please provide at least one field (description or account info).');
      return;
    }

    try {
      await recordUserNote(token, record.user_id, noteData);
      message.success('Changes saved successfully!');
    } catch (error) {
      console.error('Error response:', error.response);
      message.error('Failed to save changes.', error);
    } finally {
      handleQueryUsers(currentPage);
    }
  };

  // redirect to modal, which deals with api logic
  const handleRejectClick = (record) => {
    setCurrentUser(record);
    setIsRejectModalVisible(true);
  };

  const handleRejectModalCancel = () => {
    setIsRejectModalVisible(false);
  };

  // expand rows
  const handleExpand = (record) => {
    const keys = expandedRowKeys.includes(record.user_id) ?
      expandedRowKeys.filter(key => key !== record.user_id) :
      [...expandedRowKeys, record.user_id];
    setExpandedRowKeys(keys);
    handleQueryUsers(currentPage);
  };

  // collapse rows
  const handleTableCancel = (user_id) => {
    setExpandedRowKeys(expandedRowKeys.filter(key => key !== user_id));
  };

  const updateTableDataStatus = (userId, status) => {
    setTableData(prevData =>
      prevData.map(user =>
        user.user_id === userId ? { ...user, user_status: status } : user
      )
    );
  };

  const handlePageChange = (page) => {
    handleQueryUsers(page);
  };

  return (
    <div className={styles.userPage}>
      <Header text="User Management" loading={loading} />
      {!loading && (<>      <UserTable
        columns={columns}
        tableData={tableData}
        expandedRowKeys={expandedRowKeys}
        handleExpand={handleExpand}
        handleAccept={handleAccept}
        handleRejectClick={handleRejectClick}
        handleSaveChanges={handleSaveChanges}
        handleTableCancel={handleTableCancel}
        currentPage={currentPage}
        totalUsers={totalUsers}
        handlePageChange={handlePageChange}
        loadingUserId={loadingUserId}
        ACCEPTED={ACCEPTED}
        REJECTED={REJECTED}
        setTableData={setTableData}
      />
        <RejectUserModal
          isVisible={isRejectModalVisible}
          onConfirm={() => handleReject(currentUser)}
          onCancel={handleRejectModalCancel}
          userData={currentUser}
        />
      </>)}
    </div>
  );
}

export default UserPage;
