import React, { useState, useEffect } from 'react';
import { Table, Button, Select } from 'antd';
import { fetchQuestion, fetchActiveUsers } from '../../api/api';
import { useToken } from '../../contexts/TokenContext';
import Header from '../../components/Header/Header';
import styles from './QuestionPage.module.css';


const { Option } = Select;

function QuestionPage() {
    const [questions, setQuestions] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const { token } = useToken();
    const limit = 10;

    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState("");
    const [loading,] = useState(false);


    useEffect(() => {
        const loadQuestions = async () => {
            if (!token) return;

            const data = await fetchQuestion(token, page, limit, selectedUserId);
            if (Array.isArray(data)) {
                setQuestions(data);
                setHasMore(data.length === limit);
            } else {
                setQuestions([]);
                setHasMore(false);
            }
        };

        loadQuestions();
    }, [page, token, selectedUserId]);

    useEffect(() => {
        const loadActiveUsers = async () => {
            if (!token) return;

            const data = await fetchActiveUsers(token);
            if (Array.isArray(data)) {
                setUsers(data);
                setFilteredUsers(data);
            } else {
                setUsers([]);
                setFilteredUsers([]);
            }
        };

        loadActiveUsers();
    }, [token]);

    const handlePreviousPage = () => {
        setPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextPage = () => {
        if (hasMore) {
            setPage(prevPage => prevPage + 1);
        }
    };

    const handleFirstPage = () => {
        setPage(1);
    };

    const handleSearch = (value) => {
        setFilteredUsers(users.filter(user =>
            user.user_name.toLowerCase().includes(value.toLowerCase())
        ));
    };

    const handleSelectUser = (value) => {
        console.log("Selected user:", value);
        setSelectedUserId(value);
        setPage(1); // Reset to first page whenever a new user is selected
    };

    const columns = [
        {
            title: "Question",
            dataIndex: "content",
            key: "content",
            width: 500,
        },
        {
            title: "created_at",
            dataIndex: "created_at",
            key: "created_at",
            width: 100,
        },
        {
            title: "User ID",
            dataIndex: "user_id",
            key: "user_id",
            width: 150,
        }
    ];

    return (
        <div className={styles.container}>
            <Header text="User Questions" loading={loading} />
            <Select
                showSearch
                placeholder="Select a user"
                optionFilterProp="children"
                onSearch={handleSearch}
                onSelect={handleSelectUser}
                onBlur={() => setFilteredUsers(users)}
                style={{ width: 300, marginBottom: 20 }}
                allowClear
            >
                <Option key="all" value="">
                    All Users
                </Option>
                {filteredUsers.map(user => (
                    <Option key={`${user.user_id}-${user.user_name}`} value={user.user_id}>
                        {`${user.user_name} (${user.user_id})`}
                    </Option>
                ))}
            </Select>
            <Table
                dataSource={questions.map((question, index) => ({ ...question, key: `${question.id}-${index}` }))}
                columns={columns}
                pagination={false}
            />
            <div>
                <Button onClick={handleFirstPage}>
                    First
                </Button>
                <Button onClick={handlePreviousPage} disabled={page === 1}>
                    Previous
                </Button>
                <span> Page {page} </span>
                <Button onClick={handleNextPage} disabled={!hasMore}>
                    Next
                </Button>
            </div>
        </div>
    );
}

export default QuestionPage;
