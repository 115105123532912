import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Sidebar from './pages/Siderbar/Sidebar'
import UserPage from './pages/UserPage/UserPage';
import JobPage from './pages/JobPage/JobPage';
import QuestionPage from './pages/QuestionPage/QuestionPage';
import ResumeExtractionPage from './pages/ResumeExtractionPage/ResumeExtractionPage';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { TokenProvider, useToken } from './contexts/TokenContext';
import styles from './App.module.css';

function App({ signOut, user }) {
  const { setToken } = useToken();

  async function currentSession() {
    try {
      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
      const idTokenString = idToken.toString();
      setToken(idTokenString);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    const fetchToken = async () => {
      try {
        currentSession();
      } catch (error) {
        console.error('Error getting token:', error);
      }
    };

    fetchToken();
  }, [setToken]);

  return (
    <Router>
      <div className={styles.appContainer}>
        <Sidebar />
        <div className={styles.mainContent}>
          <Routes>
            <Route path="/" element={<Navigate to="/user" />} />
            <Route path="/user" element={<UserPage />} />
            <Route path="/job" element={<JobPage />} />
            <Route path="/resume" element={<ResumeExtractionPage />} />
            <Route path="/questions" element={<QuestionPage />} />
          </Routes>
        </div>
      </div>
    </Router >
  );
}
const AppWithAuth = withAuthenticator(App);

export default function AppWrapper() {
  return (
    <TokenProvider>
      <AppWithAuth />
    </TokenProvider>
  );
}
