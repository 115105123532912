import React from 'react';
import { Table } from 'antd';
import styles from './ResumeExtracionStatusTable.module.css';

const ResumeExtracionStatusTable = ({
    tableData,
    expandedRowKeys,
    handleExpand,
    currentPage,
    totalItems,
    handlePageChange,
}) => {
    const columns = [
        {
            title: 'User ID',
            dataIndex: 'user_id',
            key: 'user_id',
        },
        {
            title: 'Task ID',
            dataIndex: 'task_id',
            key: 'task_id',
        },
        {
            title: 'Filename',
            dataIndex: 'filename',
            key: 'filename',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Last Modified',
            dataIndex: 'last_modified',
            key: 'last_modified',
        },
        {
            title: 'Time Cost',
            dataIndex: 'cost_time',
            key: 'cost_time',
        },
        {
            title: 'Link',
            dataIndex: 's3_link',
            key: 's3_link',
            render: (text, record) => (
                <a href={record.s3_link} target="_blank" rel="noopener noreferrer">
                    View
                </a>
            ),
        },
    ];

    return (
        <>
            <Table
                bordered
                columns={columns}
                dataSource={tableData}
                rowKey={(row) => row.task_id}
                expandable={{
                    expandedRowRender: (record) => (
                        <div className={styles.jsonBox}>
                            <pre>{JSON.stringify(record, null, 2)}</pre>
                        </div>
                    ),
                    rowExpandable: (record) => true,
                    expandedRowKeys: expandedRowKeys,
                    onExpand: (expanded, record) => handleExpand(record),
                }}
                onRow={(record) => ({
                    onClick: () => handleExpand(record),
                })}
                pagination={false}
            />
            {/* <Pagination
                current={currentPage}
                pageSize={10}
                total={totalItems}
                onChange={handlePageChange}
                showSizeChanger={false}
                style={{ marginTop: '16px', textAlign: 'center' }}
            /> */}
        </>
    );
};

export default ResumeExtracionStatusTable;
