import React, { useState, useEffect } from 'react';
import {Pagination,  Select } from 'antd';
import { fetchResumeExtractionStatus } from '../../api/api';
import { useToken } from '../../contexts/TokenContext';
import ResumeExtractionStatusTable from './ResumeExtracionStatusTable/ResumeExtracionStatusTable';
import Header from '../../components/Header/Header';
import styles from './ResumeExtractionPage.module.css';

const { Option } = Select;

function ResumeExtractionPage() {
    const { token } = useToken();
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
    const [status, setStatus] = useState('');

    const fetchResumeExtractionData = async (page = 1, lastKey = null) => {
        try {
            const data = await fetchResumeExtractionStatus(token, page, 10, status, lastKey);
            if (data) {
                setTableData(data.resume_extraction_status);
                setTotalItems(data.total_items);
                setLastEvaluatedKey(data.last_evaluated_key);
            }
        } catch (error) {
            console.error('Error fetching resume extraction data:', error);
        }
    };

    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            await fetchResumeExtractionData(currentPage, lastEvaluatedKey);
            setLoading(false);
        };
        loadData();
    }, [currentPage, status]);

    const handleExpand = (record) => {
        const keys = expandedRowKeys.includes(record.task_id)
            ? expandedRowKeys.filter(key => key !== record.task_id)
            : [...expandedRowKeys, record.task_id];
        setExpandedRowKeys(keys);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setLastEvaluatedKey(null);
    };

    const handleStatusChange = (value) => {
        setStatus(value);
        setCurrentPage(1);
        setLastEvaluatedKey(null);
    };

    return (
        <div className={styles.container}>
            <Header text="Resume Extraction Status" loading={loading} />
            <div style={{ marginBottom: 16 }}>
                <label style={{ marginRight: 8 }}>Filter by Status:</label>
                <Select defaultValue="" style={{ width: 200, marginBottom: 16 }} onChange={handleStatusChange}>
                    <Option value="success">Success</Option>
                    <Option value="failed">Failed</Option>
                    <Option value="">All</Option>
                </Select>
            </div>
            {!loading && (
                <>
                    <ResumeExtractionStatusTable
                        columns={[
                            { title: 'User ID', dataIndex: 'user_id', key: 'user_id' },
                            { title: 'Task ID', dataIndex: 'task_id', key: 'task_id' },
                            { title: 'Filename', dataIndex: 'filename', key: 'filename' },
                            { title: 'Status', dataIndex: 'status', key: 'status' },
                            { title: 'Time Cost', dataIndex: 'cost_time', key: 'cost_time' },
                            { title: 'Last Modified', dataIndex: 'last_modified', key: 'last_modified' },
                            { title: 'Link', dataIndex: 's3_link', key: 's3_link', render: (text) => <a href={text} target="_blank" rel="noopener noreferrer">View</a> }
                        ]}
                        tableData={tableData}
                        expandedRowKeys={expandedRowKeys}
                        handleExpand={handleExpand}
                        currentPage={currentPage}
                        totalItems={totalItems}
                        handlePageChange={handlePageChange}
                        setTableData={setTableData}
                    />
                    <Pagination
                        current={currentPage}
                        pageSize={10}
                        total={totalItems}
                        onChange={handlePageChange}
                        showSizeChanger={false}
                        style={{ marginTop: '16px', textAlign: 'center' }}
                    />
                </>
            )}
        </div>
    );
}

export default ResumeExtractionPage;
