import React from 'react';
import { Modal, Button } from 'antd';
import styles from './RejectUserModal.module.css';

function RejectUserModal({ isVisible, onConfirm, onCancel, userData }) {
  return (
    <Modal
      title="Confirm Rejection"
      visible={isVisible}  // Corrected prop name
      onOk={() => onConfirm(userData.user_id)}
      onCancel={onCancel}
      className={styles.centeredModal}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" danger onClick={() => onConfirm(userData.user_id)}>
          Reject
        </Button>
      ]}
    >
      <p>Are you sure you want to reject {userData.full_name}?</p>
    </Modal>
  );
}

export default RejectUserModal;
