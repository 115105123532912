import axios from 'axios';
import { message } from 'antd';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const getConfig = (token) => ({
    headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'accept': 'application/json',
    }
});

// get user list
export const fetchUsers = async (token, page, limit = 10) => {
    const config = getConfig(token);
    const offset = (page - 1) * limit; // Calculate offset based on page number
    try {
        const response = await axios.get(`/api/v1/accounts`, {
            ...config,
            params: { limit, offset }
        });
        return response.data.data.accounts;
    } catch (error) {
        handleError(error);
    }
};


export const fetchTotalUsers = async (token) => {
    const config = getConfig(token);
    try {
        const response = await axios.get(`/api/v1/accounts`, {
            ...config,
            params: { limit: 10000, offset: 0 } // Fetch a large number to get all users
        });
        return response.data.data.accounts.length;
    } catch (error) {
        handleError(error);
    }
};


// accept and reject
export const updateUserDecision = async (token, userId, decision) => {
    try {
        const config = getConfig(token);
        return await axios.post(`/api/v1/accounts/${userId}/decision`, decision, config);
    } catch (error) {
        handleError(error);
    }
};

// save changes
export const recordUserNote = async (token, userId, noteData) => {
    const config = getConfig(token);
    try {
        const response = await axios.post(`/api/v1/accounts/${userId}/note`, noteData, config);
        return response.data;
    } catch (error) {
        console.error('Error response:', error.response);
        throw error;
    }
};


// handle errors
const handleError = (error) => {
    if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.error('Error response:', error.response);
        switch (error.response.status) {
            case 500:
                message.error('Server error. Please try again later.');
                break;
            case 401:
                message.error('Unauthorized access. Please check your token.');
                break;
            default:
                message.error(`Error: ${error.response.data.message || 'Error fetching data. Please try again later.'}`);
                break;
        }
    } else if (error.request) {
        // The request was made but no response was received
        console.error('Error request:', error.request);
        message.error('Error: No response received from server.');
    } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error message:', error.message);
        message.error(`Error: ${error.message}`);
    }
    throw error; // Re-throw the error after handling it
};


export const fetchJob = async (token) => {
    const config = getConfig(token);
    try {
        const response = await axios.get(`/api/v1/jobs`, {
            ...config,
        });
        return response.data.data;
    } catch (error) {
        handleError(error);
    }
};

export const fetchQuestion = async (token, page, limit = 10, user_id = "") => {
    const config = getConfig(token);
    const offset = (page - 1) * limit;
    try {
        const response = await axios.get(`/api/v1/questions`, {
            ...config,
            params: { limit, offset, user_id }
        });
        return response.data.data;
    } catch (error) {
        handleError(error);
    }
};

export const fetchActiveUsers = async (token) => {
    const config = getConfig(token);
    try {
        const response = await axios.get(`/api/v1/active_users`, {
            ...config,
            params: {}
        });
        return response.data.data;
    } catch (error) {
        handleError(error);
    }
};

export const fetchResumeExtractionStatus = async (token, page = 1, limit = 10, status = '', lastKey = null) => {
    const config = getConfig(token);
    const offset = (page - 1) * limit;
    try {
        const response = await axios.get(`/api/v1/resume_extraction`, {
            ...config,
            params: { limit, offset, status, last_evaluated_key: lastKey }
        });
        return response.data.data;
    } catch (error) {
        handleError(error);
        return null; // Ensure consistent return type
    }
};